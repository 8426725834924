import React from 'react';
import ResultsPageSearch from '@components/SearchWithCTA/ResultsPageSearch';
import SEO from '@components/SEO';
import Section from '@components/Section';
import Topbar from '@components/Topbar';
import FAQsCardViewerDesktop from '@components/FAQsCardViewerDesktop';
import { useQueryParams } from 'use-query-params';
import { DEFAULT_URL_FAQs_QUERY } from '@helpers/methods/filterContents/constants';
import filterFaqsContents from '@helpers/methods/filterContents';
import FAQsCardViewerMobile from '@components/FAQsCardViewerMobile';

const FAQsResultsViewer = ({ data }) => {
  const { flexSearchOptions, searchSuggestions, institutionFaqs } = data;
  const [filters, setFilters] = useQueryParams(DEFAULT_URL_FAQs_QUERY);

  const handleSearchQuery = (response) => {
    const { results, query, page } = response;

    const isQueryInvalid = typeof query !== 'string';
    if (isQueryInvalid) return;

    const hasSameQueryAsBefore = filters.query === query;
    if (hasSameQueryAsBefore) return;

    const sanitizeQuery = (queryString) => {
      const isQueryEmptyString = queryString === '';
      return isQueryEmptyString ? undefined : queryString;
    };

    setFilters({
      search: results,
      query: sanitizeQuery(query),
      page: page,
    });
  };
  const visibleContents = filterFaqsContents(institutionFaqs, filters);

  return (
    <div className="faq-results-section">
      <SEO title="FAQs Results" description="Results" />
      <ResultsPageSearch
        header="How can we help you?"
        searchPlaceholder="Type your keyword…"
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
        onQuery={handleSearchQuery}
        defaultValue={filters.query}
      />
      <Section className="topbar-section">
        <Topbar count={visibleContents?.length} />
      </Section>

      <Section className="faqs-jobseeker-items">
        <FAQsCardViewerDesktop faqsContents={visibleContents} />
        <FAQsCardViewerMobile faqsContents={visibleContents} />
      </Section>
    </div>
  );
};

export default FAQsResultsViewer;
